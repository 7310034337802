import { Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import DecoderText from 'components/DecoderText';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import Heading from 'components/Heading';
import Section from 'components/Section';
import { useTheme } from 'components/ThemeProvider';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { media } from 'utils/style';
import { useWindowSize } from 'hooks';
import './Intro.css';
import Text from 'components/Text';
import { NavLink } from 'react-router-dom';

function Intro({ id, sectionRef, disciplines, scrollIndicatorHidden, ...rest }) {
  const theme = useTheme();
  const titleId = `${id}-title`;
  const windowSize = useWindowSize();

  return (
    <Section
      className="grey_intro"
      as="section"
      ref={sectionRef}
      id={id}
      aria-labelledby={titleId}
      tabIndex={-1}
      {...rest}
    >
      <Transition
        key={theme.themeId}
        appear={!prerender}
        in={!prerender}
        timeout={500}
        onEnter={reflow}
      >
        {status => (
          <Fragment>
            <div className="grey_header-intro">
              <div className="grey_intro__text">
                <Heading
                  className={classNames(
                    'heading heading--level-3 grey_intro__name',
                    `grey_intro__name--${status}`
                  )}
                  level={3}
                  as="h1"
                >
                  <DecoderText text="GREY HOUSE" start={!prerender} delay={300} />
                </Heading>
                <Text size="xl" className="mb-2">
                  Kapsayıcı, Şeffaf, Sürdürülebilir
                </Text>
                <Text size="xl" className="mb-2">
                  İç ve dış paydaşları süreçlere ve karar alma mekanizmalarına dahil
                  ederek çevre, toplum ve ekonomiyi etkileyen kararlar, faaliyetler
                  hakkında açık bir tutum sergileyen; paydaşlar ile dürüst ve net iletişim
                  sağlayan, çevresel ve sosyal sorumluluklarının bilincinde kurumsal
                  sürdürülebilirlik anlayışına sahip Mimarlık ve Mühendislik Ofisi.
                </Text>
              </div>
              <div className="animated-arrow">
                <NavLink to="/projelerimiz" className="nav-link">
                  <div className="animated-arrow-wrapper">
                    <span className="the-arrow -left">
                      <span className="shaft"></span>
                    </span>
                    <span className="main">
                      <span className="text">
                        <Text size="xl">Projelerimizi Keşfet</Text>
                      </span>
                      <span className="the-arrow -right">
                        <span className="shaft"></span>
                      </span>
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="animated-arrow">
                <NavLink to="/yonetim" className="nav-link">
                  <div className="animated-arrow-wrapper">
                    <span className="the-arrow -left">
                      <span className="shaft"></span>
                    </span>
                    <span className="main">
                      <span className="text">
                        <Text size="xl">Yönetimle tanışın</Text>
                      </span>
                      <span className="the-arrow -right">
                        <span className="shaft"></span>
                      </span>
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
            {windowSize.width > media.tablet && (
              <div
                className={classNames(
                  'intro__scroll-indicator',
                  `intro__scroll-indicator--${status}`,
                  { 'intro__scroll-indicator--hidden': scrollIndicatorHidden }
                )}
                status={status}
              />
            )}
            {windowSize.width <= media.tablet && (
              <div
                className={classNames(
                  'intro__mobile-scroll-indicator',
                  `intro__mobile-scroll-indicator--${status}`,
                  { 'intro__mobile-scroll-indicator--hidden': scrollIndicatorHidden }
                )}
              >
                <ArrowDown aria-hidden />
              </div>
            )}
          </Fragment>
        )}
      </Transition>
    </Section>
  );
}

export default Intro;
