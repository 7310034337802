import { Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import Image from 'components/Image';
import Section from 'components/Section';

import { reflow } from 'utils/transition';
import { media } from 'utils/style';
import Heading from 'components/Heading';
import './Team.css';
import Text from 'components/Text';
import { NavLink } from 'react-router-dom';

const Team = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="section grey-team"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <Fragment>
            <div className="team-section">
              <div className="team-header">
                <div className="head">
                  <Heading className={classNames('team__header')} level={3} as="h1">
                    Ekibimizle tanışın
                  </Heading>
                </div>

              </div>
              <div className="team-content">
                <div className="team-profile">
                    <Image
                      reveal
                      delay={100}
                      placeholder="/team-founder.webp"
                      srcSet={`/team-founder.webp  250px`}
                      sizes={`(max-width: ${media.mobile}px) 100vw`}
                      alt="Kürşat Eker"
                    />
                  <h2>Kürşat Eker</h2>
                  <Text size="s">Kurucu Ortak</Text>
                  <Text size="s" className="mb-3" style={{ color: '#888' }}>
                    Yüksek İnşaat Mühendisi
                  </Text>
                </div>
                <div className="team-profile">
                    <Image
                      reveal
                      delay={100}
                      placeholder="/team-designer.webp"
                      srcSet={`/team-designer.webp  250px`}
                      sizes={`(max-width: ${media.mobile}px) 100vw`}
                      alt="Nisa Eker"
                    />
                  <h2>Nisanur Eker</h2>
                  <Text size="s">Kurucu Ortak</Text>
                  <Text size="s" style={{ color: '#888' }}>
                    Mimar
                  </Text>
                </div>
                <div className="team-profile">
                  <Image
                    reveal
                    delay={100}
                    placeholder="/team-architect.webp"
                    srcSet={`/team-architect.webp  250px`}
                    sizes={`(max-width: ${media.mobile}px) 100vw`}
                    alt="İlayda Dilara Usta"
                  />
                  <h2>İlayda Dilara Eker</h2>
                  <Text size="s">Proje Yöneticisi</Text>
                  <Text size="s" style={{ color: '#888' }}>
                    Mimar
                  </Text>
                </div>
                <div className="team-profile">
                  <Image
                    reveal
                    delay={100}
                    placeholder="/team-static-specialist.webp"
                    srcSet={`/team-static-specialist.webp  250px`}
                    sizes={`(max-width: ${media.mobile}px) 100vw`}
                    alt="Burak Duzcu"
                  />
                  <h2>Burak Duzcu</h2>
                  <Text size="s">Statik Proje Uzmanı</Text>
                  <Text size="s" style={{ color: '#888' }}>
                    İnşaat Mühendisi
                  </Text>
                </div>
                <div className="team-profile">
                  <Image
                    reveal
                    delay={100}
                    placeholder="/team-project-specialist.webp"
                    srcSet={`/team-project-specialist.webp  250px`}
                    sizes={`(max-width: ${media.mobile}px) 100vw`}
                    alt="Safa Günder"
                  />
                  <h2>Safa Günder</h2>
                  <Text size="s">Mekanik Proje Uzmanı</Text>
                  <Text size="s" style={{ color: '#888' }}>
                    Makine Mühendisi
                  </Text>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Transition>
    </Section>
  );
};

export default Team;
